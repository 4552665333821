type keys = "y+" | "M+" | "d+" | "H+" | "m+" | "s+" | "W+";
type Dukey = {
  [key in keys]: number;
};

export const format = (date: Date, fmt: string) => {
  let ret;
  const o: Dukey = {
    "y+": date.getFullYear(),
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "H+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
    "W+": date.getDay(),
  };
  // 类型断言
  (Object.keys(o) as (keyof Dukey)[]).forEach((key) => {
    ret = new RegExp("(" + key + ")").exec(fmt);
    if (ret) {
      let str = o[key].toString();
      const len = ret[1].length;
      if (len !== 1 && len > str.length) {
        str = addZero(str, len - str.length);
      }
      fmt = fmt.replace(ret[1], str);
    }
  });
  return fmt;
};

export const addZero = (str: string, len: number) => {
  return `${new Array(len).fill(0).join("")}${str}`;
};

export const getImageBase64 = (imgUrl: string) => {
  return new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL;
    const xhr = new XMLHttpRequest();
    xhr.open("get", imgUrl, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (this.status === 200) {
        //得到一个blob对象
        const blob = this.response;
        // console.log("blob", blob);
        // 至关重要
        const oFileReader = new FileReader();
        oFileReader.onloadend = function (e: any) {
          // 此处拿到的已经是base64的图片了,可以赋值做相应的处理
          // console.log(e.target.result);
          resolve(e.target.result);
        };
        oFileReader.readAsDataURL(blob);
      }
    };
    xhr.send();
  });
};

export const sliceTopic = (topic: string) => {
  const reportIndex = topic.indexOf("/message/property/report");
  topic = topic.slice(0, reportIndex);
  const lastIndex = topic.lastIndexOf("/") + 1;
  topic = topic.slice(lastIndex);

  const typeIndex = topic.lastIndexOf("_") + 1;
  const typeStr = topic.slice(0, typeIndex);
  const serialNumber = parseInt(topic.slice(typeIndex));
  return {
    topic,
    typeStr,
    serialNumber,
  };
};

export const getUrlParams = (url: string) => {
  // 通过 ? 分割获取后面的参数字符串
  const urlStr = url.split("?")[1];
  // 创建空对象存储参数
  const obj: any = {};
  // 再通过 & 将每一个参数单独分割出来
  const paramsArr = (urlStr && urlStr.split("&")) || [];
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    const arr = paramsArr[i].split("=");
    obj[arr[0]] = arr[1];
  }
  return obj;
};

/**
 * 删除URL中指定参数，返回被删除后的url
 * @param {string} paramKey 删除的key
 * @returns 返回被删除后的url
 */
export const delUrlParam = (paramKey: string) => {
  let url = window.location.href; //页面url
  const urlParam = window.location.search.substr(1); //页面参数
  const beforeUrl = url.substr(0, url.indexOf("?")); //页面主地址（参数之前地址）
  let nextUrl = "";

  const arr = [];
  if (urlParam != "") {
    const urlParamArr = urlParam.split("&"); //将参数按照&符分成数组
    for (let i = 0; i < urlParamArr.length; i++) {
      const paramArr = urlParamArr[i].split("="); //将参数键，值拆开
      //如果键雨要删除的不一致，则加入到参数中
      if (paramArr[0] != paramKey) {
        arr.push(urlParamArr[i]);
      }
    }
  }
  if (arr.length > 0) {
    nextUrl = "?" + arr.join("&");
  }
  url = beforeUrl + nextUrl;
  return url;
};

/**
 * 截取URL字符串第一个/后面的内容
 * @param {string} url 字符串参数
 * @returns 返回符合的字符串
 */
export const getContentAfterFirstSlash = (url: string) => {
  const urlArr = url.split("/");
  return urlArr?.length ? urlArr[1] : url;
};
